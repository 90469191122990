@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	font-size: 16px;
	width: 100%;
	overflow-x: hidden;
}

body {
	background: $color-black;
	color: $color-white;
	font-family: $primary-font, sans-serif;
	line-height: 1.5;
	width: 100%;
	overflow-x: hidden;
}

a {
	color: inherit;
	transition: opacity .3s;
	@include hover-focus{
		opacity: .6;	
	}
}

.page-wrapper{
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	#main{
		flex: 1;
	}
	#main-mail{
		flex: 1;
	}
	.menu-toggle{
		position: fixed;
		z-index: 10;
		top: rems(20);
		left: rems(30);

	}

	.menu{
		position: fixed;
		opacity: 0;
		pointer-events: none;
		z-index: 9;
		top: 0;
		left: 0;
		background-color: $color-black;
		color: $color-white;
		min-width: rems(185);
		padding: rems(60) rems(30) rems(30);
		transition: opacity .4s;
		a{
			display: block;
		}
		a+a{
			margin-top: rems(10);
		}
		&.active{
			opacity: 1;
			pointer-events: all;
		}
	}
	.menu-toggle, .menu{
		img{
			height: rems(24);
			display: block;
			@media screen and (max-width: 460px){
				height: rems(20);
			}
		}
	}

	// .float-link{
	// 	position: fixed;
	// 	z-index: 10;
	// 	img{
	// 		height: rems(24);
	// 		display: block;
	// 		@media screen and (max-width: 460px){
	// 			height: rems(20);
	// 		}
	// 	}
	// 	&:nth-child(1){
	// 		top: rems(20);
	// 		left: rems(20);
	// 	}
	// 	&:nth-child(2){
	// 		top: rems(20);
	// 		right: rems(20);
	// 	}
	// 	&:nth-child(3){
	// 		bottom: rems(20);
	// 		left: rems(20);
	// 	}
	// 	&:nth-child(4){
	// 		bottom: rems(20);
	// 		right: rems(20);
	// 	}
	// 	&:nth-child(5){
	// 		bottom: rems(20);
	// 		left: 50%;
	// 		transform: translateX(-50%);
	// 	}
	// }
}

#header {
	h1{
		width: 100%;
		max-width: rems(280);
		margin: 0 auto;
		
		@media screen and (max-width: 460px){
			max-width: rems(250);
			padding-top: rems(50);
		}
	}
	
	.sticker{
		position: absolute;
		top: rems(70);
		left: calc(50% + 320px);
		width: rems(220);
		transform: rotate(14deg);
		display: block;
		img{
			display: block;
		}
		
		@media screen and (max-width: 1000px){
			left: auto;
			right: rems(15);
		}
		@media screen and (max-width: 800px){
			margin: 0 0 0 auto;
			max-width: rems(200);
			position: relative;
			top: rems(10);
			margin-bottom: rems(-80);
		}
		
		@media screen and (max-width: 400px){
			margin-bottom: rems(-40);
		}
	}
}


.social{
	display: flex;
	justify-content: center;
	align-items: center;
	gap: rems(12);
	margin-bottom: rems(30);
	a{
		width: rems(24);
	}
}

#main-mail{
	display: flex;
	max-height: calc(100vh - 7.5rem);
	max-width: rems(1600); 
	width: 100%;
	gap: rems(15);
	padding: rems(15);
	margin: 0 auto;
	align-items: stretch;
	@media screen and (max-width: 900px){
		max-height: none;
		flex-direction: column;
		align-items: center;

	}
	.col{
		background-color:#121212;
		border: 1px solid #544541;
		padding:  rems(10) rems(20) rems(25);
		flex: 1;
		display: flex;
		flex-direction: column;
		
		@media screen and (max-width: 900px){
			width: 100% !important;
			max-width: 100%;
			&.mailbox{
				display: none;
			}
		}
		.box{
			flex: 1;
			border: 1px solid $color-black;
			padding: rems(20);
			background-color: #1c1a1a;
			overflow-y: scroll;
			
			&::-webkit-scrollbar {
				width: 5px !important;
				height: 5px !important;
			}
			&::-webkit-scrollbar-thumb {
				background-color: #cfc9c5 !important;
				border: 1px solid #1a1a1a !important;
				border-radius: 1px !important;
			}
		}
		.logo{
			width: rems(94);
		}
		.bar{
			height: rems(36);
			border-bottom: 1px solid #1c1a1a;
			margin-bottom: rems(10);
			p{
				margin: 0;
			}
		}
		&.selector{
			width: rems(400);
			flex-basis: rems(400);
			flex-grow: 0;
			max-width: 100%;
			a{
				padding: rems(12) 0;
				display: flex;
				align-items: center;
				gap: rems(20);
				text-decoration: none;
				border-bottom: 1px solid #544541;
				img{
					width: rems(26);
				}
				.title{
					flex: 1;
					font-size: rems(10);
					line-height: 1.2;
				}
				.time{
					font-size: rems(10);
					line-height: 1.2;
				}
			}
		}
	}

	.mail-post{
		text-align: center;
		p{
			width: 100%;
			margin: 1em auto;
			max-width: rems(600);
		}
		img{
			display: block;
			margin: rems(30) auto;
			&.title{
				max-height: 20px;
				filter: brightness(0) invert(1);
			}
			&.title-tall{
				max-height:50px;
				filter: brightness(0) invert(1);
			}
			&.banner{
				width: 100%;
				max-width: 524px;
			}
			&.btn-link{
				max-height: 31px;
				filter: brightness(0) invert(1);
			}
			&.gfx{
				width: 100%;
				max-width: 400px;
			}
		}
		hr{
			opacity: .5;
			margin: rems(30) 0;
		}

		.wrap{
			display: flex;
			justify-content: center;
			gap: rems(30);
			>div{
				width: 100%;
				max-width: rems(240);
				p{
					margin: 1em 0 0;
				}
			}
			a{
				text-decoration: none;
			}
			
			@media screen and (max-width: 900px){
				flex-direction: column;
				align-items: center;
			}
		}
	}
}

.mailpage{
	background-image: url('../img/bg-mail.png');
	background-size: cover;
	@media screen and (min-width: 901px){
		height: 100vh;
		.page-wrapper{
			height: 100vh;
		}
	}
	.container{
		max-width: rems(1600);
	}
	#header{
		padding: rems(20);
		h1{
			max-width: rems(80);
			margin: 0 0 0 auto;
		}

	}
	#footer{
		padding: 0;
		.container{
			display: flex;
			justify-content: space-between;
			
			@media screen and (max-width: 900px){
				padding-bottom: rems(10);
				flex-direction: column;
				align-items: center;
				text-align: center;
				.social{
					margin-bottom: rems(15);
				}
			}
			.copyright{
				width: rems(500);
				max-width: 100%;
			}
		}
		.social a{
			width: rems(20);
		}
		.copyright, .social{
			margin: 0;
		}
	}
}

#main {
	padding: rems(50) rems(10) 0;
	.video{
		width: rems(750);
		max-width: 100%;
		margin: 0 auto rems(30);
	}

	.music-item{ 
		display: block;
		width: rems(550);
		max-width: 100%;
		margin: 0 auto;
		img{
			width: 100%;
			height: auto;
			display: block;
		}
	}

	.img-wrapper{
		width: rems(550);
		max-width: 100%;
		margin: 0 auto rems(30);
	}

	#home, #music{
		text-align: center;
		.listen-link{
			display: inline-block;
			margin: rems(30) auto;
			img{
				max-width: 100%;
				width: auto;
				height: auto;
			}
		}
	}

	#bio{
		.container{
			max-width: rems(1030);
			font-size: rems(14);
			font-weight: 900;
		}
	}

	#subscribe{
		font-weight: 1000;
		font-size: rems(16);
		text-transform: uppercase;
		text-align: center;
		.container{
			max-width: rems(400);
		}
		input{
			
			background: transparent;
			border: none;
			border-bottom: 2px solid $color-white;
			color: $color-white;
			display: block;
			width: 100%;
			text-align: left;
			padding: rems(10);
			margin-bottom: rems(15);
			font-weight: 1000;
			font-size: rems(16);
			text-transform: uppercase;
			&::placeholder{
				color: $color-white;
				
				font-weight: 1000;
				font-size: rems(16); 
				text-transform: uppercase;
			}
		}
		button{
			display: inline-block;
			border: 2px solid $color-white;
			background: transparent;
			color: $color-white;
			padding: rems(10) rems(20);
			
			font-weight: 1000;
			font-size: rems(16);
			text-transform: uppercase;
			@include hover-focus{
				color: $color-black;
				background-color: $color-white;
			}
		}
	}
}

#footer {
	padding: rems(50) 0;
	font-size: rems(8);
	text-transform: uppercase;
	text-align: center;
}